import { SAVE_PERMISSIONS, SAVE_ACCESS_SCREENS } from "../../actiontypes"

// **  Initial State
const initialState = {
  userData: {},
  isFetching: false,
  errMsg: '',
  successMsg: '',
  permissions: {},
  accessScreens: [],
  successToken: ''
}

const authReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // login cases
    case 'LOGIN_START':
      return {
        ...state,
        isFetching: true
      }
    case SAVE_ACCESS_SCREENS:
      return {
        ...state,
        accessScreens: payload
      }
    case SAVE_PERMISSIONS:
      return {
        ...state,
        permissions: payload
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        userData: {
          ...payload.data.user,
          [payload.config.storageTokenKeyName]: payload[payload.config.storageTokenKeyName]
        }
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isFetching: false,
        errMsg: payload.errMsg
      }
    case 'FORGOT_PASSWORD_START':
      return {
        ...state,
        isFetching: true
      }
    case 'FORGOT_PASSWORD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        successMsg: payload.successMsg
      }
    case 'RESET_PASSWORD_TOKEN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        successMsg: payload.successMsg,
        successToken: payload.successToken
      }

    case 'FORGOT_PASSWORD_FAILURE':
      return {
        ...state,
        isFetching: false,
        errMsg: payload.errMsg
      }
    case 'RESET_PASSWORD_START':
      return {
        ...state,
        isFetching: true
      }
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        successMsg: payload.successMsg
      }
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        isFetching: false,
        errMsg: payload.errMsg
      }
    case 'UPDATE_PASSWORD_START':
      return {
        ...state,
        isFetching: true

      }
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        successMsg: payload.successMsg
      }
    case 'UPDATE_PASSWORD_FAILURE':
      return {
        ...state,
        isFetching: false,
        errMsg: payload.errMsg
      }
    case 'RESET_USER_PASSWORD_START':
      return {
        ...state,
        isFetching: true,
        successMsg: ''

      }
    case 'RESET_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        successMsg: payload.successMsg,
        successToken: ''
      }
    case 'RESET_USER_PASSWORD_FAILURE':
      return {
        ...state,
        isFetching: false,
        errMsg: payload.errMsg
      }

    case 'LOGOUT':
      const obj = { ...payload }
      delete obj.type
      return {
        ...state,
        userData: {},
        ...obj,
        [payload.config.storageTokenKeyName]: payload[payload.config.storageTokenKeyName]
      }
    default:
      return state
  }
}

// [action.config.storageRefreshTokenKeyName]:
//   action[action.config.storageRefreshTokenKeyName]

export default authReducer
