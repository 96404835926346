import {
  GET_ALL_PRODUCTS_START,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_SINGLE_PRODUCT_START,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  CREATE_PRODUCT_START,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  EDIT_PRODUCT_START,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  DELETE_PRODUCT_START,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  REMOVE_SINGLE_PRODUCT_SUCCESS
} from '../../actiontypes'
// **  Initial State
const initialState = {
  products: [],
  singleProduct: {},
  isFetching: false,
  errMsg: '',
  productsCount: null
}

const productReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all products
    case GET_ALL_PRODUCTS_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload?.data,
        isFetching: false,
        productsCount: payload?.count
      }
    case GET_ALL_PRODUCTS_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle getting single products
    case GET_SINGLE_PRODUCT_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, singleProduct: payload?.data, isFetching: false }
    case GET_SINGLE_PRODUCT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single products
    case CREATE_PRODUCT_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [payload.data, ...state?.products],
        isFetching: false
      }
    case CREATE_PRODUCT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single products
    case EDIT_PRODUCT_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_PRODUCT_SUCCESS: {
      const itemIndex = state.products.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.products]
      newArray[itemIndex] = payload?.data
      return { ...state, products: newArray, isFetching: false }
    }
    case EDIT_PRODUCT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single products
    case DELETE_PRODUCT_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_PRODUCT_SUCCESS: {
      const newArray = state.products.filter((item) => item._id !== payload.id)
      return { ...state, products: newArray, isFetching: false }
    }
    case DELETE_PRODUCT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    case REMOVE_SINGLE_PRODUCT_SUCCESS: {
      return { ...state, isFetching: false, singleProduct: {} }
    }
    default:
      return state
  }
}

export default productReducer
