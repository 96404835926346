import {
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  CREATE_CUSTOMER_START,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  EDIT_CUSTOMER_START,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_START,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  customers: [],
  customersCount: 0,
  isFetching: false,
  errMsg: ''
}

const customerReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all email customers
    case GET_CUSTOMER_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: payload?.data,
        isFetching: false,
        customersCount: payload?.count
      }
    case GET_CUSTOMER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single email customer
    case CREATE_CUSTOMER_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: [payload.data, ...state?.customers],
        isFetching: false
      }
    case CREATE_CUSTOMER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single email customer
    case EDIT_CUSTOMER_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_CUSTOMER_SUCCESS: {
      const itemIndex = state.customers.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.customers]
      newArray[itemIndex] = payload?.data
      return { ...state, customers: newArray, isFetching: false }
    }
    case EDIT_CUSTOMER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single email customer
    case DELETE_CUSTOMER_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_CUSTOMER_SUCCESS: {
      const newArray = state.customers.filter((item) => item._id !== payload.id)
      return { ...state, customers: newArray, isFetching: false }
    }
    case DELETE_CUSTOMER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default customerReducer
