import {
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  //
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  //
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  //
  DELETE_ROLE_START,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE
} from '../../actiontypes'
import { updateUserList } from '../utils'
// **  Initial State

const initialState = {
  roles: [],
  role: {},
  isFetching: false,
  roleCount: null,
  errMsg: ''
}

const roleReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all brands
    case GET_ROLES_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: payload?.data,
        roleCount: payload?.count,
        isFetching: false
      }

    case GET_ROLES_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single brand
    case CREATE_ROLE_START:
      return {
        ...state,
        isFetching: true
      }

    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: [payload?.data, ...state.roles],
        isFetching: false
      }

    case CREATE_ROLE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single brand
    case UPDATE_ROLE_START:
      return {
        ...state,
        isFetching: true
      }

    case UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        roles: updateUserList(payload.data, state.roles),
        isFetching: false
      }
    }

    case UPDATE_ROLE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single brand
    case DELETE_ROLE_START:
      return {
        ...state,
        isFetching: true
      }

    case DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        roles: state.roles.filter(({ _id }) => _id !== payload.role._id),
        isFetching: false
      }
    }

    case DELETE_ROLE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    default:
      return state
  }
}

export default roleReducer
