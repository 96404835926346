import {
  GET_ACCESS_SCREEN_START,
  GET_ACCESS_SCREEN_SUCCESS,
  GET_ACCESS_SCREEN_FAILURE,
  //
  DELETE_ACCESS_SCREEN_START,
  DELETE_ACCESS_SCREEN_FAILURE,
  DELETE_ACCESS_SCREEN_SUCCESS,
  //
  UPDATE_ACCESS_SCREEN_FAILURE,
  UPDATE_ACCESS_SCREEN_SUCCESS,
  UPDATE_ACCESS_SCREEN_START,
  //
  CREATE_ACCESS_SCREEN_START,
  CREATE_ACCESS_SCREEN_SUCCESS,
  CREATE_ACCESS_SCREEN_FAILURE
} from '../../actiontypes'
import { updateUserList } from '../utils'
// **  Initial State

const initialState = {
  accessScreens: [],
  searchedAccessScreens: [],
  accessScreen: {},
  isFetching: false,
  accessScreenCount: null,
  errMsg: ''
}

const accessScreensReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all brands
    case GET_ACCESS_SCREEN_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_ACCESS_SCREEN_SUCCESS:
      return {
        ...state,
        accessScreens: payload.condition === false ? state.accessScreens : payload?.data,
        searchedAccessScreens: payload.condition === false ? payload?.data : [],
        accessScreenCount: payload?.count,
        isFetching: false
      }
    case GET_ACCESS_SCREEN_FAILURE:
      return {
        ...state,
        errMsg: payload.errMsg,
        isFetching: false
      }

    // Handle creating single brand
    case CREATE_ACCESS_SCREEN_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_ACCESS_SCREEN_SUCCESS:
      return {
        ...state,
        accessScreens: [payload?.data, ...state.accessScreens],
        isFetching: false
      }
    
    case CREATE_ACCESS_SCREEN_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single brand
    case UPDATE_ACCESS_SCREEN_START:
      return {
        ...state,
        isFetching: true
      }

    case UPDATE_ACCESS_SCREEN_SUCCESS: {
      return {
        ...state,
        accessScreens: updateUserList(payload.data, state.accessScreens),
        isFetching: false
      }
    }

    case UPDATE_ACCESS_SCREEN_FAILURE:
      return {
        ...state,
        errMsg: payload.errMsg,
        isFetching: false
      }

    // Handle deleting single brand
    case DELETE_ACCESS_SCREEN_START:
      return {
        ...state,
        isFetching: true
      }

    case DELETE_ACCESS_SCREEN_SUCCESS: {
      return {
        ...state,
        accessScreens: state.accessScreens.filter(
          ({ _id }) => _id !== payload.accessScreen._id
        ),
        isFetching: false
      }
    }

    case DELETE_ACCESS_SCREEN_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    default:
      return state
  }
}

export default accessScreensReducer
