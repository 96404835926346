import {
  GET_EMAIL_TEMPLATE_START,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_FAILURE,
  CREATE_EMAIL_TEMPLATE_START,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_FAILURE,
  EDIT_EMAIL_TEMPLATE_START,
  EDIT_EMAIL_TEMPLATE_SUCCESS,
  EDIT_EMAIL_TEMPLATE_FAILURE,
  DELETE_EMAIL_TEMPLATE_START,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  templates: [],
  isFetching: false,
  errMsg: ''
}

const emailTemplateReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all email templates
    case GET_EMAIL_TEMPLATE_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: payload?.data,
        isFetching: false
      }
    case GET_EMAIL_TEMPLATE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single email template
    case CREATE_EMAIL_TEMPLATE_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: [payload.data, ...state?.templates],
        isFetching: false
      }
    case CREATE_EMAIL_TEMPLATE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single email template
    case EDIT_EMAIL_TEMPLATE_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_EMAIL_TEMPLATE_SUCCESS: {
      const itemIndex = state.templates.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.templates]
      newArray[itemIndex] = payload?.data
      return { ...state, templates: newArray, isFetching: false }
    }
    case EDIT_EMAIL_TEMPLATE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single email template
    case DELETE_EMAIL_TEMPLATE_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_EMAIL_TEMPLATE_SUCCESS: {
      const newArray = state.templates.filter((item) => item._id !== payload.id)
      return { ...state, templates: newArray, isFetching: false }
    }
    case DELETE_EMAIL_TEMPLATE_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default emailTemplateReducer
