// AUTH
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'


export const RESET_PASSWORD_TOKEN_SUCCESS = 'RESET_PASSWORD_TOKEN_SUCCESS'
export const RESET_USER_PASSWORD_START = 'RESET_USER_PASSWORD_START'
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS'
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE'


//  GET ALL ROLES
export const GET_ROLES_START = 'GET_ROLES_START'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE'

// DELETE ROLE
export const DELETE_ROLE_START = 'DELETE_ROLE_START'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE'

// CREATE ROLE
export const CREATE_ROLE_START = 'CREATE_ROLE_START'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE'

// UPDATE ROLE
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE'

// FETCH ACCESS_SCREEN
export const GET_ACCESS_SCREEN_START = 'GET_ACCESS_SCREEN_START'
export const GET_ACCESS_SCREEN_SUCCESS = 'GET_ACCESS_SCREEN_SUCCESS'
export const GET_ACCESS_SCREEN_FAILURE = 'GET_ACCESS_SCREEN_FAILURE'
export const SAVE_PERMISSIONS = 'SAVE_PERMISSIONS'
export const SAVE_ACCESS_SCREENS = 'SAVE_ACCESS_SCREENS'

//DELETE ACCESS SCREEN
export const DELETE_ACCESS_SCREEN_START = 'DELETE_ACCESS_SCREEN_START'
export const DELETE_ACCESS_SCREEN_SUCCESS = 'DELETE_ACCESS_SCREEN_SUCCESS'
export const DELETE_ACCESS_SCREEN_FAILURE = 'DELETE_ACCESS_SCREEN_FAILURE'

// CREATE ACCESS_SCREEN
export const CREATE_ACCESS_SCREEN_START = 'CREATE_ACCESS_SCREEN_START'
export const CREATE_ACCESS_SCREEN_SUCCESS = 'CREATE_ACCESS_SCREEN_SUCCESS'
export const CREATE_ACCESS_SCREEN_FAILURE = 'CREATE_ACCESS_SCREEN_FAILURE'

// UPDATE ACCESS_SCREEN
export const UPDATE_ACCESS_SCREEN_START = 'UPDATE_ACCESS_SCREEN_START'
export const UPDATE_ACCESS_SCREEN_SUCCESS = 'UPDATE_ACCESS_SCREEN_SUCCESS'
export const UPDATE_ACCESS_SCREEN_FAILURE = 'UPDATE_ACCESS_SCREEN_FAILURE'

// get all users
export const GET_USERS_START = 'GET_USERS_START'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

// get all users
export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

// create user
export const CREATE_USER_START = 'CREATE_USER_START'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
// edit users
export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
// edit users
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

// Weight Units
export const GET_WEIGHT_UNIT_START = 'GET_WEIGHT_UNIT_START'
export const GET_WEIGHT_UNIT_SUCCESS = 'GET_WEIGHT_UNIT_SUCCESS'
export const GET_WEIGHT_UNIT_FAILURE = 'GET_WEIGHT_UNIT_FAILURE'
export const CREATE_WEIGHT_UNIT_START = 'CREATE_WEIGHT_UNIT_START'
export const CREATE_WEIGHT_UNIT_SUCCESS = 'CREATE_WEIGHT_UNIT_SUCCESS'
export const CREATE_WEIGHT_UNIT_FAILURE = 'CREATE_WEIGHT_UNIT_FAILURE'
export const EDIT_WEIGHT_UNIT_START = 'EDIT_WEIGHT_UNIT_START'
export const EDIT_WEIGHT_UNIT_SUCCESS = 'EDIT_WEIGHT_UNIT_SUCCESS'
export const EDIT_WEIGHT_UNIT_FAILURE = 'EDIT_WEIGHT_UNIT_FAILURE'
export const DELETE_WEIGHT_UNIT_START = 'DELETE_WEIGHT_UNIT_START'
export const DELETE_WEIGHT_UNIT_SUCCESS = 'DELETE_WEIGHT_UNIT_SUCCESS'
export const DELETE_WEIGHT_UNIT_FAILURE = 'DELETE_WEIGHT_UNIT_FAILURE'

// Weight Units
export const GET_BRAND_START = 'GET_BRAND_START'
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS'
export const GET_BRAND_FAILURE = 'GET_BRAND_FAILURE'
export const CREATE_BRAND_START = 'CREATE_BRAND_START'
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS'
export const CREATE_BRAND_FAILURE = 'CREATE_BRAND_FAILURE'
export const EDIT_BRAND_START = 'EDIT_BRAND_START'
export const EDIT_BRAND_SUCCESS = 'EDIT_BRAND_SUCCESS'
export const EDIT_BRAND_FAILURE = 'EDIT_BRAND_FAILURE'
export const DELETE_BRAND_START = 'DELETE_BRAND_START'
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS'
export const DELETE_BRAND_FAILURE = 'DELETE_BRAND_FAILURE'

//category
export const GET_CATEGORY_START = 'GET_CATEGORY_START'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE'
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'
export const EDIT_CATEGORY_START = 'EDIT_CATEGORY_START'
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS'
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE'
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'

// Currency
export const GET_CURRENCY_START = 'GET_CURRENCY_START'
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS'
export const GET_CURRENCY_FAILURE = 'GET_CURRENCY_FAILURE'
export const CREATE_CURRENCY_START = 'CREATE_CURRENCY_START'
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS'
export const CREATE_CURRENCY_FAILURE = 'CREATE_CURRENCY_FAILURE'
export const EDIT_CURRENCY_START = 'EDIT_CURRENCY_START'
export const EDIT_CURRENCY_SUCCESS = 'EDIT_CURRENCY_SUCCESS'
export const EDIT_CURRENCY_FAILURE = 'EDIT_CURRENCY_FAILURE'
export const DELETE_CURRENCY_START = 'DELETE_CURRENCY_START'
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS'
export const DELETE_CURRENCY_FAILURE = 'DELETE_CURRENCY_FAILURE'

// Company
export const GET_COMPANY_START = 'GET_COMPANY_START'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE'
export const CREATE_COMPANY_START = 'CREATE_COMPANY_START'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE'
export const EDIT_COMPANY_START = 'EDIT_COMPANY_START'
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS'
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE'
export const DELETE_COMPANY_START = 'DELETE_COMPANY_START'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE'

// PRODUCTS
export const GET_ALL_PRODUCTS_START = 'GET_ALL_PRODUCTS_START'
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS'
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE'
export const GET_SINGLE_PRODUCT_START = 'GET_SINGLE_PRODUCT_START'
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS'
export const GET_SINGLE_PRODUCT_FAILURE = 'GET_SINGLE_PRODUCT_FAILURE'
export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'
export const EDIT_PRODUCT_START = 'EDIT_PRODUCT_START'
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS'
export const EDIT_PRODUCT_FAILURE = 'EDIT_PRODUCT_FAILURE'
export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE'
export const REMOVE_SINGLE_PRODUCT_SUCCESS = 'REMOVE_SINGLE_PRODUCT_SUCCESS'

// Email Template
export const GET_EMAIL_TEMPLATE_START = 'GET_EMAIL_TEMPLATE_START'
export const GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS'
export const GET_EMAIL_TEMPLATE_FAILURE = 'GET_EMAIL_TEMPLATE_FAILURE'
export const CREATE_EMAIL_TEMPLATE_START = 'CREATE_EMAIL_TEMPLATE_START'
export const CREATE_EMAIL_TEMPLATE_SUCCESS = 'CREATE_EMAIL_TEMPLATE_SUCCESS'
export const CREATE_EMAIL_TEMPLATE_FAILURE = 'CREATE_EMAIL_TEMPLATE_FAILURE'
export const EDIT_EMAIL_TEMPLATE_START = 'EDIT_EMAIL_TEMPLATE_START'
export const EDIT_EMAIL_TEMPLATE_SUCCESS = 'EDIT_EMAIL_TEMPLATE_SUCCESS'
export const EDIT_EMAIL_TEMPLATE_FAILURE = 'EDIT_EMAIL_TEMPLATE_FAILURE'
export const DELETE_EMAIL_TEMPLATE_START = 'DELETE_EMAIL_TEMPLATE_START'
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS'
export const DELETE_EMAIL_TEMPLATE_FAILURE = 'DELETE_EMAIL_TEMPLATE_FAILURE'

// Orders
export const GET_ORDER_START = 'GET_ORDER_START'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'
export const GET_SINGLE_ORDER_START = 'GET_SINGLE_ORDER_START'
export const GET_SINGLE_ORDER_SUCCESS = 'GET_SINGLE_ORDER_SUCCESS'
export const GET_SINGLE_ORDER_FAILURE = 'GET_SINGLE_ORDER_FAILURE'
export const CREATE_ORDER_START = 'CREATE_ORDER_START'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'
export const EDIT_ORDER_START = 'EDIT_ORDER_START'
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS'
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE'
export const DELETE_ORDER_START = 'DELETE_ORDER_START'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE'

// Customers
export const GET_CUSTOMER_START = 'GET_CUSTOMER_START'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE'
export const CREATE_CUSTOMER_START = 'CREATE_CUSTOMER_START'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'
export const EDIT_CUSTOMER_START = 'EDIT_CUSTOMER_START'
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS'
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE'
export const DELETE_CUSTOMER_START = 'DELETE_CUSTOMER_START'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE'

// Customers
export const GET_CONTACTS_START = 'GET_CONTACTS_START'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE'
//getsupportstart
export const GET_SUPPORT_START = 'GET_SUPPORT_START'
export const GET_SUPPORT_SUCCESS = 'GET_SUPPORT_SUCCESS'
export const GET_SUPPORT_FAILURE = 'GET_SUPPORT_FAILURE'

export const CREATE_SUPPORT_START = 'CREATE_SUPPORT_START'
export const CREATE_SUPPORT_SUCCESS = 'CREATE_SUPPORT_SUCCESS'
export const CREATE_SUPPORT_FAILURE = 'CREATE_SUPPORT_FAILURE'

//editsupportstart
export const EDIT_SUPPORT_START = 'EDIT_SUPPORT_START'
export const EDIT_SUPPORT_SUCCESS = 'EDIT_SUPPORT_SUCCESS'
export const EDIT_SUPPORT_FAILURE = 'EDIT_SUPPORT_FAILURE'