import {
  GET_CURRENCY_START,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_FAILURE,
  CREATE_CURRENCY_START,
  CREATE_CURRENCY_SUCCESS,
  CREATE_CURRENCY_FAILURE,
  EDIT_CURRENCY_START,
  EDIT_CURRENCY_SUCCESS,
  EDIT_CURRENCY_FAILURE,
  DELETE_CURRENCY_START,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  currencies: [],
  isFetching: false,
  errMsg: ''
}

const currencyReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all currencies
    case GET_CURRENCY_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        currencies: payload?.data,
        isFetching: false
      }
    case GET_CURRENCY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single currency
    case CREATE_CURRENCY_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_CURRENCY_SUCCESS:
      return {
        ...state,
        currencies: [payload.data, ...state?.currencies],
        isFetching: false
      }
    case CREATE_CURRENCY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single currency
    case EDIT_CURRENCY_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_CURRENCY_SUCCESS: {
      const itemIndex = state.currencies.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.currencies]
      newArray[itemIndex] = payload?.data
      return { ...state, currencies: newArray, isFetching: false }
    }
    case EDIT_CURRENCY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single currency
    case DELETE_CURRENCY_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_CURRENCY_SUCCESS: {
      const newArray = state.currencies.filter((item) => item._id !== payload.id)
      return { ...state, currencies: newArray, isFetching: false }
    }
    case DELETE_CURRENCY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default currencyReducer
