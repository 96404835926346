import {
  GET_CONTACTS_START,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  contactsCount: 0,
  contacts: [],
  isFetching: false,
  errMsg: ''
}

const contactsReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all companies
    case GET_CONTACTS_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: payload?.data,
        contactsCount: payload?.count,
        isFetching: false
      }
    case GET_CONTACTS_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single company
    default:
      return state
  }
}

export default contactsReducer
