import {
  GET_ORDER_START,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_SINGLE_ORDER_START,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_FAILURE,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  EDIT_ORDER_START,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  DELETE_ORDER_START,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  orders: [],
  ordersCount: 0,
  isFetching: false,
  errMsg: '',
  currentOrder: {}
}

const orderReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all email orders
    case GET_ORDER_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        orders: payload?.data,
        isFetching: false,
        ordersCount: payload?.count
      }
    case GET_ORDER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    case GET_SINGLE_ORDER_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        currentOrder: payload?.data
      }
    case GET_SINGLE_ORDER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single email order
    case CREATE_ORDER_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: [payload.data, ...state?.orders],
        isFetching: false
      }
    case CREATE_ORDER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single email order
    case EDIT_ORDER_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_ORDER_SUCCESS: {
      const itemIndex = state.orders.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.orders]
      newArray[itemIndex] = payload?.data
      return {
        ...state,
        orders: newArray,
        isFetching: false
      }
    }
    case EDIT_ORDER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single email order
    case DELETE_ORDER_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_ORDER_SUCCESS: {
      const newArray = state.orders.filter((item) => item._id !== payload.id)
      return { ...state, orders: newArray, isFetching: false }
    }
    case DELETE_ORDER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default orderReducer
