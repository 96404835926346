import {
  GET_COMPANY_START,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  CREATE_COMPANY_START,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  EDIT_COMPANY_START,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  DELETE_COMPANY_START,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  companyCount: 0,
  companies: [],
  isFetching: false,
  errMsg: ''
}

const companyReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all companies
    case GET_COMPANY_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        companies: payload?.data,
        companyCount: payload?.count,
        isFetching: false
      }
    case GET_COMPANY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single company
    case CREATE_COMPANY_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [payload.data, ...state?.companies],
        isFetching: false
      }
    case CREATE_COMPANY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single company
    case EDIT_COMPANY_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_COMPANY_SUCCESS:
      const itemIndex = state.companies.findIndex((item) => item._id === payload.data._id)
      const newArray = [...state.companies]
      newArray[itemIndex] = payload.data
      return { ...state, companies: newArray, isFetching: false }

    case EDIT_COMPANY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single company
    case DELETE_COMPANY_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_COMPANY_SUCCESS:
      {
        const newArray = state.companies.filter((item) => item._id !== payload?.id)
        return { ...state, companies: newArray, isFetching: false }
      }
    case DELETE_COMPANY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default companyReducer
