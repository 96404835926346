// ** Logo
import logo from '@src/assets/images/logo/logo-with-text.svg'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      {/* <h2 className="fallback-text brand-text mb-0">
        Order Booker
      </h2> */}
      <img className='fallback-logo' src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
