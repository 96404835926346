import {
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from '../actiontypes'
import { updateUserList } from './utils'

// **  Initial State
const initialState = {
  // roles states
  roles: [],
  role: {},
  rolesCount: null,

  // users states
  users: [],
  user: {},
  userCount: null,

  // general states
  isFetching: false,
  errMsg: '',
  successMsg: ''
}

const userReducer = (state = initialState, { payload, type }) => {
  // console.log('rolsinreducer====>', payload)
  switch (type) {
    // Handle getting all products
    case GET_ROLES_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_ROLES_SUCCESS:
      // console.log('rolsinreducer====>', roles)
      return {
        ...state,
        roles: payload?.data,
        isFetching: false,
        rolesCount: payload?.count
      }
    case GET_ROLES_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Creating the users cases
    case CREATE_USER_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [payload.data, ...state.users],
        isFetching: false
      }
    case CREATE_USER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // UPDATING the users cases
    case UPDATE_USER_START:
      return {
        ...state,
        isFetching: true
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: updateUserList(payload.data, state.users),
        isFetching: false
      }
    case UPDATE_USER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // DELETING the users cases
    case DELETE_USER_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(({ _id }) => _id !== payload.id),
        isFetching: false
      }
    case DELETE_USER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // GETTING the users cases
    case GET_USERS_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload.data,
        userCount: payload.count,
        isFetching: false
      }
    case GET_USERS_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    case GET_USER_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: payload.data,
        isFetching: false
      }
    case GET_USER_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default userReducer
