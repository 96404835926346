import {
  GET_BRAND_START,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAILURE,
  CREATE_BRAND_START,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAILURE,
  EDIT_BRAND_START,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_FAILURE,
  DELETE_BRAND_START,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  brands: [],
  isFetching: false,
  errMsg: '',
  brandsCount: 0
}

const brandReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all brands
    case GET_BRAND_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        brands: payload?.data,
        brandsCount: payload?.count,
        isFetching: false
      }
    case GET_BRAND_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single brand
    case CREATE_BRAND_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        brands: [payload.data, ...state?.brands],
        isFetching: false
      }
    case CREATE_BRAND_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single brand
    case EDIT_BRAND_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_BRAND_SUCCESS: {
      const itemIndex = state.brands.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.brands]
      newArray[itemIndex] = payload?.data
      return { ...state, brands: newArray, isFetching: false }
    }
    case EDIT_BRAND_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single brand
    case DELETE_BRAND_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_BRAND_SUCCESS: {
      const newArray = state.brands.filter((item) => item._id !== payload.id)
      return { ...state, brands: newArray, isFetching: false }
    }
    case DELETE_BRAND_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default brandReducer
