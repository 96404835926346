import {
  GET_WEIGHT_UNIT_START,
  GET_WEIGHT_UNIT_SUCCESS,
  GET_WEIGHT_UNIT_FAILURE,
  CREATE_WEIGHT_UNIT_START,
  CREATE_WEIGHT_UNIT_SUCCESS,
  CREATE_WEIGHT_UNIT_FAILURE,
  EDIT_WEIGHT_UNIT_START,
  EDIT_WEIGHT_UNIT_SUCCESS,
  EDIT_WEIGHT_UNIT_FAILURE,
  DELETE_WEIGHT_UNIT_START,
  DELETE_WEIGHT_UNIT_SUCCESS,
  DELETE_WEIGHT_UNIT_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  weightUnits: [],
  isFetching: false,
  errMsg: ''
}

const weightUnitReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all weight units
    case GET_WEIGHT_UNIT_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_WEIGHT_UNIT_SUCCESS:
      return {
        ...state,
        weightUnits: payload?.data,
        isFetching: false
      }
    case GET_WEIGHT_UNIT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single weight unit
    case CREATE_WEIGHT_UNIT_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_WEIGHT_UNIT_SUCCESS:
      return {
        ...state,
        weightUnits: [payload.data, ...state?.weightUnits],
        isFetching: false
      }
    case CREATE_WEIGHT_UNIT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single weightUnit
    case EDIT_WEIGHT_UNIT_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_WEIGHT_UNIT_SUCCESS: {
      const itemIndex = state.weightUnits.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.weightUnits]
      newArray[itemIndex] = payload?.data
      return { ...state, weightUnits: newArray, isFetching: false }
    }
    case EDIT_WEIGHT_UNIT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single weightUnit
    case DELETE_WEIGHT_UNIT_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_WEIGHT_UNIT_SUCCESS: {
      const newArray = state.weightUnits.filter((item) => item._id !== payload.id)
      return { ...state, weightUnits: newArray, isFetching: false }
    }
    case DELETE_WEIGHT_UNIT_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default weightUnitReducer
