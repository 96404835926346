import {
  GET_CATEGORY_START,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  CREATE_CATEGORY_START,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  EDIT_CATEGORY_START,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAILURE,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE
} from '../../actiontypes'
// **  Initial State
const initialState = {
  category: [],
  isFetching: false,
  errMsg: '',
  categoryCount: 0
}

const categoryReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Handle getting all category
    case GET_CATEGORY_START:
      return {
        ...state,
        isFetching: true
      }
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: payload?.data,
        categoryCount: payload?.count,
        isFetching: false
      }
    case GET_CATEGORY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle creating single brand
    case CREATE_CATEGORY_START:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: [payload.data, ...state?.category],
        isFetching: false
      }
    case CREATE_CATEGORY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle editing single brand
    case EDIT_CATEGORY_START:
      return {
        ...state,
        isFetching: true
      }
    case EDIT_CATEGORY_SUCCESS: {
      const itemIndex = state.category.findIndex((item) => item._id === payload?.data?._id)
      const newArray = [...state.category]
      newArray[itemIndex] = payload?.data
      return { ...state, category: newArray, isFetching: false }
    }
    case EDIT_CATEGORY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }

    // Handle deleting single brand
    case DELETE_CATEGORY_START:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_CATEGORY_SUCCESS: {
      const newArray = state.category.filter((item) => item._id !== payload.id)
      return { ...state, category: newArray, isFetching: false }
    }
    case DELETE_CATEGORY_FAILURE:
      return { ...state, errMsg: payload.errMsg, isFetching: false }
    default:
      return state
  }
}

export default categoryReducer
