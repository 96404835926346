
// **  Initial State
const initialState = {

    showModal: false,
    data: null
}

const modalReducer = (state = initialState, { data, type }) => {
    switch (type) {
        case 'SHOW_MODAL':
            return {
                ...state,
                showModal: true,
                data
            }

        case 'HIDE_MODAL':
            return {
                ...state,
                showModal: false,
                data
            }

        default:
            return state
    }
}


export default modalReducer
