// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
// import logger from 'redux-logger'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
// import persistReducer from 'redux-persist/es/persistReducer'

// ** init middleware
const middleware = [createDebounce(), thunk]

// const persistConfig = {
//   key: 'root',
//   storage
// }

if (process.env.NODE_ENV === 'development') {
  // middleware.push(logger)
}

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

const persistor = persistStore(store)

export { store, persistor }
